import React, { useEffect, useState, useContext } from "react";
import { RouteComponentProps } from "@reach/router";
import { Badge, Col, Container, Row } from "react-bootstrap";
import Auth from "@aws-amplify/auth";
import { Helmet } from "react-helmet";

import { Paths } from "../utils/routes";
import { DashboardItem, Heading } from "../components";
import { formatLongDate } from "libs/dates";
import { UserContext } from "../components/User/UserContext";

const Dashboard: React.FC<RouteComponentProps> = () => {
  const [date, setDate] = useState("");
  const { user, saveUser } = useContext(UserContext);

  useEffect(() => {
    // Use as entry-point for logged in fresh user
    Auth.currentAuthenticatedUser({
      bypassCache: false,
    })
      .then((userData) => {
        const { username } = userData;
        const { name, email } = userData.attributes;

        // save the user details to context, only trigger on username change
        if (user.username !== username) {
          saveUser({ username, name, email, limited: userData.attributes["custom:limit_admin"] || false });
        }
      })
      .catch((err) => { });

    setDate(formatLongDate(new Date()));
  }, [saveUser, user.username]);

  return (
    <>
      <Helmet>
        <title>Dashboard | S12 Admin</title>
      </Helmet>
      <Container>
        <Row>
          <Col sm={6}>
            <h1 className="text-center text-sm-left">Dashboard</h1>
            {user && (
              <p className="mb-0 text-center text-sm-left">
                <span className="text-muted">Logged in as: </span>
                <strong>&nbsp;{user.name}</strong>
              </p>
            )}
          </Col>
          <Col sm={6} className="d-flex flex-column justify-content-end align-items-center align-items-sm-end">
            <p className="mt-2 mt-sm-0 mb-0 text-muted">{date}</p>
          </Col>
        </Row>
        <hr className="mb-5" />

        {user && (
          <>
            <Heading tag="h2">
              <Badge variant="warning" className="mr-3">
                <span className="mdi mdi-account-outline" />
              </Badge>
              Reports
            </Heading>
            <ul className="list-group">
              <DashboardItem text="Export doctor availability" path={Paths.ExportDoctorAvailability} />
              <DashboardItem text="Export site reports" path={Paths.ExportSiteReports} />
            </ul>
            <br />
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
