import { Dashboard } from "../pages";
import { ExportDoctorAvailability } from "../components/ExportDoctorAvailability";
import { ExportSiteReports } from "../components/ExportSiteReports";

export enum Paths {
  Dashboard = "/",
  ExportDoctorAvailability = "/export-doctor-availability",
  ExportSiteReports = "/export-site-reports",
}

// Order of objects here is order they appear in nav bar
export const Routes = {
  TopLevel: [
    {
      label: "Dashboard",
      path: Paths.Dashboard,
      component: Dashboard,
    },
  ],
  SubPages: [
    {
      path: Paths.ExportDoctorAvailability,
      component: ExportDoctorAvailability,
    },
    {
      path: Paths.ExportSiteReports,
      component: ExportSiteReports,
    },
  ],
};
