// https://www.figma.com/file/PGEaLIYqd5WgTRqfdORaqEo9/%23Library

export const palette = {
  aqua: "#2AD2C9",
  aquaFaded: "#B1E4E3",
  blue: "#0072CE",
  blueFaded: "#A7C6ED",
  yellow: "#FFC600",
  yellowFaded: "#F6EB61",
  red: "#FF5C39",
  redFaded: "#FFB3AB",
  purple: "#9B7DD4",
  purpleFaded: "#C5B4E3",
  navy: "#002B49",
  slate: "#435570",
  greyBlue: "#647692",
  grey: "#EAECF2",
  cloud: "#F5F6FA",
  white: "#FFFFFF",
  transparent: "transparent",
  shadow: "#000000",
  rippleColor: "rgba(51,75,161,0.025)",
};
