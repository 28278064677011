import React, { useState, useEffect, FormEvent, useContext } from "react";
import Helmet from "react-helmet";
import { Heading } from ".";
import { Row, Form, Col, InputGroup, Container, Button } from "react-bootstrap";
import FormInputIcon from "./FormInputIcon";
import dayjs from "dayjs";
import { graphqlOperation, API } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import ResponseWrapper from "./ResponseWrapper";
import { GraphQLResult } from "@aws-amplify/api";
import { ToastContext } from "./Toast/ToastContext";

const EXPORT_AVAILABILITY = `query AdminExportAvailability($siteName: String, $start: String, $end: String) {
    data: adminExportAvailability(start: $start, end: $end, siteName: $siteName)
}`;

const LIST_SITES = `query ListSites {
    listSites(limit: 500) {
        items {
          id
          name
        }
      }
}
`;

interface ListSitesResponse {
  listSites: {
    items: Array<{
      id: string;
      name: string;
    } | null> | null;
  };
}

interface ConnectQueryRender {
  data: ListSitesResponse;
  loading: boolean;
  errors: any[];
}

export function ExportDoctorAvailability() {
  function doExport(input: { siteName?: string; start: string; end: string }) {
    console.log("input", input);
    return (API.graphql(graphqlOperation(EXPORT_AVAILABILITY, input)) as Promise<
      GraphQLResult<{ data: string[][] }>
    >).then((d) => d.data?.data || [[]]);
  }

  return (
    <Connect query={graphqlOperation(LIST_SITES)}>
      {(response: ConnectQueryRender) => {
        if (response.errors.length || response.loading) return <ResponseWrapper response={response} />;
        return (
          <ExportDoctorAvailabilityForm
            getAvailabilityData={doExport}
            sites={response.data.listSites?.items?.filter(notEmpty) || []}
          />
        );
      }}
    </Connect>
  );
}

function ExportDoctorAvailabilityForm(props: {
  getAvailabilityData: (input: { siteName?: string; start: string; end: string }) => Promise<string[][]>;
  sites: { name: string }[];
}) {
  const [siteName, setSiteName] = useState("");
  const [startDate, setStartDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const [endDate, setEndDate] = useState(dayjs().add(15, "day").format("YYYY-MM-DD"));

  const { dispatch: toastDispatch } = useContext(ToastContext);

  const updateStartDate = (start: string) => {
    if (dayjs(endDate).diff(dayjs(start), "day") > 30) {
      setEndDate(dayjs(start).add(30, "day").format("YYYY-MM-DD"));
    } else if (dayjs(endDate).diff(dayjs(start), "day") < 1) {
      setEndDate(dayjs(start).add(1, "day").format("YYYY-MM-DD"));
    }

    setStartDate(start);
  };

  const updateEndDate = (end: string) => {
    if (dayjs(end).diff(dayjs(startDate), "day") > 30) {
      setStartDate(dayjs(end).subtract(30, "day").format("YYYY-MM-DD"));
    } else if (dayjs(end).diff(dayjs(startDate), "day") < 1) {
      setStartDate(dayjs(end).subtract(1, "day").format("YYYY-MM-DD"));
    }
    setEndDate(end);
  };

  useEffect(() => {
    if (dayjs(endDate).diff(dayjs(startDate), "day") > 30) {
    }
  }, [startDate, endDate]);

  const onFormSubmit = (e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    props
      .getAvailabilityData({
        siteName: siteName.length ? siteName : undefined,
        start: startDate,
        end: endDate,
      })
      .then((data: string[][]) => {
        setLoading(false);
        let csvContent = "data:text/csv;charset=utf-8," + data.map((e) => e.map((e) => `"${e}"`).join(",")).join("\n");
        const encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute(
          "download",
          `availability_export_${startDate}_${endDate}_${siteName && siteName.length > 0 ? siteName : "All"}.csv`
        );
        document.body.appendChild(link); // Required for FF
        link.click();
      })
      .catch((e) => {
        console.log("error:", e);
        toastDispatch({ type: "addToast", payload: { type: "failure", message: e.message } });
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>Export Availability | S12 Admin</title>
      </Helmet>

      <Container>
        <Heading tag="h2">Export Availability</Heading>
        <Row className="d-flex flex-column-reverse justify-content-between">
          <Form onSubmit={onFormSubmit}>
            <Form.Row className="mt-1">
              <Form.Group as={Col} xs={12} md={2}>
                <Form.Label>
                  <small className="text-muted">Site Name</small>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={siteName}
                  id="siteName"
                  name="siteName    "
                  onChange={(e) => setSiteName(e.currentTarget.value || "")}
                >
                  <option value=""> -- Select Site -- </option>
                  {props.sites.map((s) => (
                    <option key={s.name} value={s.name}>
                      {s.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row className="mt-1">
              <Col xs={12} lg={4}>
                <div className="form-group">
                  <label htmlFor="start" className="form-label">
                    <small className="text-muted">Start Date</small>
                  </label>
                  <InputGroup>
                    <FormInputIcon icon="calendar-today" />
                    <input
                      className="form-control"
                      type="date"
                      required={true}
                      name="start"
                      id="start"
                      value={startDate}
                      onChange={(e) => updateStartDate(e.currentTarget.value)}
                    />
                  </InputGroup>
                </div>
              </Col>
              <Col xs={12} lg={4}>
                <div className="form-group">
                  <label htmlFor="end" className="form-label">
                    <small className="text-muted">End Date</small>
                  </label>
                  <InputGroup>
                    <FormInputIcon icon="calendar-today" />
                    <input
                      className="form-control"
                      type="date"
                      required={true}
                      name="end"
                      id="end"
                      value={endDate}
                      onChange={(e) => updateEndDate(e.currentTarget.value)}
                    />
                  </InputGroup>
                </div>
              </Col>
            </Form.Row>
            <Form.Row>
              <Button type="submit" variant="primary" disabled={loading}>
                {loading ? "Processing..." : "Export To CSV"}
              </Button>
            </Form.Row>
          </Form>
        </Row>
      </Container>
    </>
  );
}

function notEmpty<T>(value: T | null | undefined): value is T {
  return !!value;
}
