import React from "react";
import Auth from "@aws-amplify/auth";
import { Button } from "react-bootstrap";

import { ReactComponent as Logo } from "../images/s12_logo.svg";

const Unauthorised: React.FC = () => (
  <div
    className="d-flex flex-column align-items-center justify-content-center"
    style={{ minHeight: "100vh" }}
  >
    <Logo width={50} height={50} className="mb-5" />
    <h1 className="">Unauthorised</h1>
    <Button
      size="lg"
      variant="primary"
      className="mt-5"
      onClick={() => Auth.signOut()}
    >
      <span className="mdi mdi-logout"></span> Logout
    </Button>
  </div>
);

export default Unauthorised;
