import Config from "react-web-config";
import dotenvParseVariables from "dotenv-parse-variables";

// react-config-web and react-native-config have slightly different ways to get all constants
// console.log(JSON.stringify(Config));
const ENV = dotenvParseVariables({
  ...(Config.getConstants ? Config.getConstants() : Config),
});

if (ENV.ENV === "prod") {
  ENV.ENABLE_CLAIMS = true;
}

ENV.ENABLE_SENTRY = ENV.ENV === "master" || ENV.ENV === "preprod" || ENV.ENV === "prod" ? "true" : false;

export default ENV;
