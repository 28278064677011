import React, { createContext, useState, useEffect } from "react";

//* IUser.limited, set in cognito, attribute: custom:limit_admin
interface IUser {
  username: string;
  name: string;
  email: string;
  limited?: boolean;
}

type ContextType = {
  user: IUser;
  saveUser: (user: IUser) => void;
  clearUser: () => void;
};

export const UserContext = createContext<ContextType>({
  user: { username: "", name: "", email: "", limited: true }, //limited by default for safety
  saveUser: () => null,
  clearUser: () => null,
});

function UserProvider(props: { children: any }) {
  // create user object and use local storage for defaults
  // this stops resetting of everything to null on page refresh

  const storedUser = JSON.parse(localStorage.getItem("user")!);

  const [user, setUser] = useState<IUser>({
    username: storedUser?.username,
    name: storedUser?.name,
    email: storedUser?.email,
    limited: storedUser?.limited,
  });

  // ensures that we save our user to local storage
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(user))
  });

  // save the user to our context
  const saveUser = (user: IUser) => {
    setUser(user);
  };

  // delete the user on logout
  const clearUser = () => {
    setUser({ username: "", name: "", email: "", limited: true }); //limited by default for safety
    localStorage.removeItem("user");
  };

  return (
    <UserContext.Provider value={{ user, saveUser, clearUser }}>
      {props.children}
    </UserContext.Provider>
  );
}

export default UserProvider;