/**
 *
 * For available icon prop values please visit: https://materialdesignicons.com/
 *
 */

import React from "react";
import { InputGroup, Spinner } from "react-bootstrap";

interface FormInputIconProps {
  icon: string;
  loading?: boolean;
}

const FormInputIcon: React.FC<FormInputIconProps> = ({
  icon,
  loading = false
}) => (
  <InputGroup.Prepend
    onClick={(e: React.MouseEvent<HTMLSpanElement>) => {
      const input = e.currentTarget.nextElementSibling as
        | HTMLInputElement
        | HTMLTextAreaElement;
      if (!input.disabled) {
        input.focus();
      }
    }}
  >
    <InputGroup.Text
      className={`text-dark ${loading ? "bg-primary" : "bg-white"}`}
    >
      {loading && (
        <Spinner animation="border" variant="light" role="status" size="sm" />
      )}
      {!loading && <span className={`mdi mdi-${icon}`} />}
    </InputGroup.Text>
  </InputGroup.Prepend>
);

export default FormInputIcon;
