import React from "react";
import { Container } from "react-bootstrap";

const Footer: React.FC = () => (
  <footer className="footer py-2 bg-light">
    <Container>
      <p className="mb-0 text-muted text-center">
        <small>&copy; S12 Solutions. &nbsp;All rights reserved.</small>
      </p>
    </Container>
  </footer>
);

export default Footer;
