/* ==========================================================================
  #AUTO LOGOUT WEB
========================================================================== */

/**
 * Automatically log the user out after a set period of time.
 * Accepts a method that handles the logout.
 * Will (optionally) display a warning before the `onLogout` method is called.
 *
 * @author Vajira Nuwan
 * @link https://www.skptricks.com/2018/12/how-to-implement-auto-logout-client-side-in-react.html
 */

import React, { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { Modal, Button, Row } from "react-bootstrap";

export interface PropTypes {
  /**
   * Callback called when the user has been inactive for
   * `logoutThresholdSeconds` (default 1 hour).
   */
  onLogout: () => void;
  /**
   * Time in seconds until the `onLogout` param is called. Defaults to 1 hour.
   */
  logoutThresholdSeconds?: number;
  /**
   * Callback called when the user has been inactive for
   * `logoutThresholdSeconds - warningThresholdSectionds`.
   */
  onWarning?: () => void;
  /**
   * Display a warning message to the user that they are about to be logged out.
   */
  showWarning?: boolean;
  /**
   * Time in seconds *before* the `onLogout` callback is called. Defaults to
   * 5 minutes.
   */
  warningThresholdSeconds?: number;
}

enum Events {
  load,
  mousedown,
  click,
  scroll,
  keypress,
}

type Timeout = ReturnType<typeof setTimeout> | null;

const getWarningTimeMessage = (threshold: number): string => {
  const expiryTime = dayjs().add(threshold, "second");
  return expiryTime.format("HH:mm");
};

dayjs.extend(relativeTime);

/**
 * Set timeouts for when the user has been inactive, which will then call a
 * warning callback (if supplied and enabled) and then the logout callback.
 *
 * A user is described as inactive if they haven't clicked, scrolled or pressed
 * a key on their device. Every time the user fires one of these events, the
 * timers are reset.
 */
const AutoLogout = (props: PropTypes) => {
  const {
    logoutThresholdSeconds = 1200,
    onLogout,
    onWarning,
    showWarning = true,
    warningThresholdSeconds = 300,
  } = props;

  const logoutTimeout = useRef<Timeout>(null);
  const warningTimeout = useRef<Timeout>(null);
  const [isWarningVisible, setWarningVisible] = useState(false);
  const logoutThreshold = logoutThresholdSeconds - warningThresholdSeconds;

  const clearTimeouts = useCallback(() => {
    logoutTimeout.current && clearTimeout(logoutTimeout.current);
    warningTimeout.current && clearTimeout(warningTimeout.current);
  }, []);

  const setTimeouts = useCallback(() => {
    logoutTimeout.current = setTimeout(() => {
      setWarningVisible(false);
      onLogout();
      clearTimeouts();
    }, logoutThresholdSeconds * 1000);

    if (showWarning) {
      warningTimeout.current = setTimeout(() => {
        setWarningVisible(true);
        onWarning && onWarning();
      }, logoutThreshold * 1000);
    }
  }, [clearTimeouts, logoutThreshold, logoutThresholdSeconds, onLogout, onWarning, showWarning]);

  const resetTimeouts = useCallback(() => {
    clearTimeouts();
    setTimeouts();
  }, [clearTimeouts, setTimeouts]);

  useEffect(
    function resetTimeoutsOnUserInteraction() {
      Object.values(Events).forEach((event: any) => window.addEventListener(event, resetTimeouts));

      return () => Object.values(Events).forEach((event: any) => window.removeEventListener(event, resetTimeouts));
    },
    [resetTimeouts]
  );

  useEffect(
    function setSetTimeoutsOnFirstMountOrPropsChange() {
      resetTimeouts();

      return clearTimeouts;
    },
    [clearTimeouts, logoutThresholdSeconds, resetTimeouts, warningThresholdSeconds]
  );

  return (
    <>
      {showWarning && isWarningVisible && (
        <Modal
          onHide={() => { }}
          show={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Session Timeout Warning
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row style={{ paddingLeft: 20 }}>
              <p>You will be logged out at &nbsp; </p>
              <span />
              <p style={{ fontWeight: "bold" }}>{`${getWarningTimeMessage(warningThresholdSeconds)}`}</p>
              <p>.</p>
            </Row>
            <Row style={{ paddingLeft: 20 }}><p>Close this notice if you wish to stay logged in.</p></Row>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button onClick={() => { setWarningVisible(false) }}>Keep Me Logged In</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default AutoLogout;
