import React, { useContext } from "react";
import Auth from "@aws-amplify/auth";
import { Link, navigate } from "@reach/router";
import { Button, Container, Navbar, Nav, Badge } from "react-bootstrap";
import { Routes, Paths } from "../utils/routes";
import { ReactComponent as Logo } from "../images/s12_logo.svg";
import ENV from "../config";
import { UserContext } from "../components/User/UserContext";

let env = ENV.ENV || "master";

const getEnvPillColour = () => {
  if (env === "prod") {
    env = "production";
    return "danger";
  }
  if (env === "preprod") {
    return "warning";
  }
  // set master or sandbox to 'development'
  env = "development";
  return "primary";
};
const envPillBg = getEnvPillColour();

const Navigation: React.FC = () => {
  const { clearUser } = useContext(UserContext);

  const links = Routes.TopLevel.map((route) => {
    const { label, path } = route;
    return (
      <Link
        key={path}
        to={path}
        getProps={({ isCurrent }) => ({
          className: isCurrent ? "nav-link active px-2" : "nav-link px-2",
        })}
      >
        <span className="text-secondary px-1">{label}</span>
      </Link>
    );
  });

  const { Brand, Collapse, Toggle } = Navbar;

  return (
    <Navbar bg="white" expand="lg" sticky="top">
      <Container className="pt-3 pb-4 py-lg-4" style={{ borderBottom: "1px solid #EAECF2" }}>
        <Link to={Paths.Dashboard}>
          <Brand className="d-flex align-items-center">
            <Logo width={26} height={26} className="mr-2" />
            <span className="ml-1">{"S12 Admin"}</span>
            <span className="mr-4"></span>
            <h2>
              <Badge variant={envPillBg} pill>
                {env}
              </Badge>
            </h2>
          </Brand>
        </Link>

        <Toggle aria-controls="s12-admin-nav-menu" />
        <Collapse id="s12-admin-nav-menu">
          <Nav className="ml-auto d-flex flex-lg-row align-items-center">
            {links}
            <Button
              variant="outline-dark"
              className="ml-3 border-secondary"
              onClick={() => window.confirm("Are you sure you want to logout?") && Auth.signOut()  && navigate("/") && clearUser()}
            >
              <span className="mdi mdi-logout"></span> Logout
            </Button>
            <span className="text-muted px-4 d-lg-none">-</span>
            <Nav.Link
              href="https://my.s12solutions.com"
              target="_blank"
              rel="noreferrer noopener"
              className="d-flex d-lg-none align-items-center"
            >
              <span className="mdi mdi-launch mr-1"></span> S12 App
            </Nav.Link>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default Navigation;
