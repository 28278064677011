import React from "react";
import { Router } from "@reach/router";
import Amplify from "@aws-amplify/core";
import API from "@aws-amplify/api";
import Auth from "@aws-amplify/auth";
import { withAuthenticator } from "aws-amplify-react";

import { palette } from "libs/theme";

import Config from "./config";
import { Routes } from "./utils/routes";
import { Footer, Navigation, Unauthorised } from "./components";
import ToastContextProvider from "./components/Toast/ToastContext";
import UserProvider from "./components/User/UserContext";
import AutoLogout from "./components/AutoLogout";

configureAmplify();

interface AppProps {
  authState: String;
  authData: any;
}

const AppTheme = {
  button: { backgroundColor: palette.blue, color: palette.white },
  a: { color: palette.blue, textDecoration: "underline" },
  inputLabel: { color: palette.navy },
  hint: { color: palette.greyBlue },
};

const App: React.FC<AppProps> = (props) => {
  const cognitoGroups = props.authData.signInUserSession.idToken.payload["cognito:groups"];

  if (!cognitoGroups.includes("S12Admin")) {
    return <Unauthorised />;
  }

  const routes = [...Routes.TopLevel, ...Routes.SubPages]
    // Filter out those routes that require full access [requiresFullAccess]
    // and where the current user doesn't have that access [shouldLimitAccess]
    // .filter((route) => {
    // no routes with this options now
    // if (props.authData?.attributes && props.authData.attributes["custom:limit_admin"]) {
    // const shouldLimitAccess = JSON.parse(props.authData.attributes["custom:limit_admin"]);
    // if (shouldLimitAccess && "requiresFullAccess" in route && !!route.requiresFullAccess) {
    //   return false;
    // }
    // return true;
    // }
    // return true;
    // })
    .map((route) => {
      const { component, path } = route;
      const Page = component;
      return <Page key={path} path={path} />;
    });

  return (
    <>
      <UserProvider>
        <ToastContextProvider>
          <Navigation />
          <AutoLogout onLogout={() => { Auth.signOut() }} />
          <Router id="router" className="py-4 py-sm-5">
            {routes}
          </Router>
          <Footer />
        </ToastContextProvider>
      </UserProvider>
    </>
  );
};

export default withAuthenticator(App, false, [], null, AppTheme);

function configureAmplify() {
  Amplify.configure({
    aws_appsync_graphqlEndpoint: Config.AWS_APPSYNC_GRAPHQLENDPOINT,
    aws_appsync_region: Config.AWS_PROJECT_REGION,
    aws_project_region: Config.AWS_PROJECT_REGION,
    aws_cognito_identity_pool_id: Config.AWS_COGNITO_IDENTITY_POOL_ID,
    aws_cognito_region: Config.AWS_COGNITO_REGION,
    aws_user_pools_id: Config.AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: Config.AWS_USER_POOLS_WEB_CLIENT_ID,
    aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
    Auth: {
      identityPoolId: Config.AWS_COGNITO_IDENTITY_POOL_ID,
      region: Config.AWS_PROJECT_REGION,
    },
  });

  API.configure({
    endpoints: [
      {
        name: "signupResend",
        endpoint: `https://lambda.eu-west-2.amazonaws.com/2015-03-31/functions/signupResend-${Config.ENV}/invocations`,
        service: "lambda",
        region: "eu-west-2",
      },
    ],
  });

  Auth.configure({
    storage: window.sessionStorage,
  });
}
