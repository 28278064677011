import React, { useState, FormEvent, useContext } from "react";
import Helmet from "react-helmet";
import { Heading } from ".";
import { Row, Form, Col, InputGroup, Container, Button } from "react-bootstrap";
import FormInputIcon from "./FormInputIcon";
import dayjs from "dayjs";
import { graphqlOperation, API } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import ResponseWrapper from "./ResponseWrapper";
import { GraphQLResult } from "@aws-amplify/api";
import { ToastContext } from "./Toast/ToastContext";

const EXPORT_AMHP_REPORT = `query AdminExportAmhpReport($siteId: String!, $siteName: String!, $yearMonth: String!) {
    data: adminAmhpReport(siteId: $siteId, siteName: $siteName, yearMonth: $yearMonth)
}`;

const EXPORT_DOCTOR_REPORT = `query AdminExportDoctorReport($siteId: String!, $siteName: String!, $yearMonth: String!) {
  data: adminDoctorReport(siteId: $siteId, siteName: $siteName, yearMonth: $yearMonth)
}`;

const EXPORT_SITE_SUMMARY_REPORT = `query AdminExportSiteReport($siteId: String!, $siteName: String!, $yearMonth: String!) {
  data: adminSiteReport(siteId: $siteId, siteName: $siteName, yearMonth: $yearMonth)
}`;

const LIST_SITES = `query ListSites {
    listSites(limit: 500) {
        items {
          id
          name
        }
      }
}
`;

interface ListSitesResponse {
  listSites: {
    items: Array<{
      id: string;
      name: string;
    } | null> | null;
  };
}

interface ConnectQueryRender {
  data: ListSitesResponse;
  loading: boolean;
  errors: any[];
}

type ReportType = "amhp" | "doctor" | "summary";

export function ExportSiteReports() {
  return (
    <Connect query={graphqlOperation(LIST_SITES)}>
      {(response: ConnectQueryRender) => {
        if (response.errors.length || response.loading) return <ResponseWrapper response={response} />;
        return (
          <ExportSiteReportForm
            getReportData={doExport}
            sites={response.data.listSites?.items?.filter(notEmpty) || []}
          />
        );
      }}
    </Connect>
  );
}

/**
 * the api returns a string[][] which the file download will directly translate into a csv file
 */
function doExport(input: { siteId: string; siteName: string; yearMonth: string; type: ReportType }) {
  let query = queryFromType(input.type);
  return (API.graphql(graphqlOperation(query, input)) as Promise<GraphQLResult<{ data: string[][] }>>).then(
    (d) => d.data?.data || [[]]
  );
}

function queryFromType(type: ReportType) {
  if (type === "amhp") {
    return EXPORT_AMHP_REPORT;
  } else if (type === "doctor") {
    return EXPORT_DOCTOR_REPORT;
  } else if (type === "summary") {
    return EXPORT_SITE_SUMMARY_REPORT;
  }
}

type SiteType = {
  id: string;
  name: string;
};

function ExportSiteReportForm(props: {
  getReportData: (input: {
    siteId: string;
    siteName: string;
    yearMonth: string;
    type: ReportType;
  }) => Promise<string[][]>;
  sites: SiteType[];
}) {
  const [currentSite, setCurrentSite] = useState<SiteType>({ id: "", name: "" });
  const [yearMonth, setYearMonth] = useState(dayjs().format("YYYY-MM-DD"));
  const [loading, setLoading] = useState(false);
  const { dispatch: toastDispatch } = useContext(ToastContext);

  const handleSelectSite = (name: string) => {
    const selectedSite: SiteType | undefined = props.sites.find((s) => s.name === name);
    if (selectedSite) {
      setCurrentSite(selectedSite);
    }
  };
  const onFormSubmit = (type: ReportType, e: FormEvent) => {
    setLoading(true);
    e.preventDefault();
    props
      .getReportData({
        siteId: currentSite.id,
        siteName: currentSite.name,
        yearMonth,
        type,
      })
      .then((data: string[][]) => {
        setLoading(false);
        createCsvDownload(data, yearMonth, currentSite.name, type);
      })
      .catch((e) => {
        console.log("error:", e);
        toastDispatch({ type: "addToast", payload: { type: "failure", message: e.message } });
        setLoading(false);
      });
  };
  return (
    <>
      <Helmet>
        <title>Export Site Reports | S12 Admin</title>
      </Helmet>

      <Container>
        <Heading tag="h2">Export Site Reports</Heading>
        <Row className="d-flex flex-column-reverse justify-content-between">
          <Form>
            <Form.Row className="mt-1">
              <Form.Group as={Col} xs={12} md={2}>
                <Form.Label>
                  <small className="text-muted">Site Name</small>
                </Form.Label>
                <Form.Control
                  as="select"
                  value={currentSite.name}
                  id="siteName"
                  name="siteName    "
                  onChange={(e) => handleSelectSite(e.currentTarget.value || "")}
                >
                  <option value=""> -- Select Site -- </option>
                  {props.sites.map((s) => (
                    <option key={s.name} value={s.name}>
                      {s.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form.Row>
            <Form.Row className="mt-1">
              <Col xs={12} lg={6}>
                <div className="form-group">
                  <label htmlFor="start" className="form-label">
                    <small className="text-muted">Month</small>
                  </label>
                  <InputGroup>
                    <FormInputIcon icon="calendar-today" />
                    <input
                      className="form-control"
                      type="month"
                      required={true}
                      name="monthYear"
                      id="monthYear"
                      value={yearMonth}
                      onChange={(e) => setYearMonth(e.currentTarget.value)}
                    />
                  </InputGroup>
                </div>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col xs={12} lg={3}>
                <Button
                  type="button"
                  variant="primary"
                  disabled={loading || !currentSite.name}
                  onClick={onFormSubmit.bind(null, "amhp")}
                >
                  {loading ? "Processing..." : "Export AMHP Report"}
                </Button>
              </Col>
              <Col xs={12} lg={3}>
                <Button
                  type="button"
                  variant="primary"
                  disabled={loading || !currentSite.name}
                  onClick={onFormSubmit.bind(null, "doctor")}
                >
                  {loading ? "Processing..." : "Export Doctor Report"}
                </Button>
              </Col>
              <Col xs={12} lg={3}>
                <Button
                  type="button"
                  variant="primary"
                  disabled={loading || !currentSite.name}
                  onClick={onFormSubmit.bind(null, "summary")}
                >
                  {loading ? "Processing..." : "Export Site Summary Report"}
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </Row>
      </Container>
    </>
  );
}

function createCsvDownload(data: string[][], yearMonth: string, siteName: string, type: ReportType) {
  let csvContent = "data:text/csv;charset=utf-8," + data.map((e) => e.map((e) => `"${e}"`).join(",")).join("\n");
  const encodedUri = encodeURI(csvContent);
  let link = document.createElement("a");
  link.setAttribute("href", encodedUri);
  link.setAttribute("download", `${type}_export_${yearMonth}__${siteName}.csv`);
  document.body.appendChild(link); // Required for FF
  link.click();
}

function notEmpty<T>(value: T | null | undefined): value is T {
  return !!value;
}
