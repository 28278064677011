import React from "react";
import { Link } from "@reach/router";
import { Paths } from "../utils/routes";

interface DashboardItemProps {
  text: string;
  path: Paths;
}

const DashboardItem: React.FC<DashboardItemProps> = ({ text, path }) => {
  return (
    <Link
      to={path}
      className="list-group-item list-group-item-action py-4 d-flex flex-row justify-content-between align-items-center"
    >
      <h5 className="mb-0 font-weight-normal">{text}</h5>
    </Link>
  );
};

export default DashboardItem;
