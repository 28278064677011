import React from "react";

interface HeadingProps {
  tag: "h1" | "h2" | "h3" | "h4" | "h5";
}

const Heading: React.FC<HeadingProps> = ({ children, tag }) => {
  const Tag = tag;

  // https://getbootstrap.com/docs/4.0/utilities/spacing/
  const padding = {
    h1: "pb-5",
    h2: "pb-4",
    h3: "pb-3",
    h4: "pb-2",
    h5: "pb-1"
  };

  return (
    <Tag className={`d-flex flex-row align-items-center ${padding[tag]}`}>
      {children}
    </Tag>
  );
};

export default Heading;
