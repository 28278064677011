import React from "react";
import { Container, Col, Row, Spinner } from "react-bootstrap";

interface ResponseWrapperProps {
  response: {
    loading: boolean;
    errors: any[];
  };
}

const ResponseWrapper: React.FC<ResponseWrapperProps> = ({ response }) => (
  <Container>
    <Col xs={12}>
      {response.loading ? (
        <Row>
          <h3 className="mr-3">Loading...</h3>
          <Spinner animation="border" variant="dark" role="status" />
        </Row>
      ) : (
        <>
          <h3>Error!</h3>
          <h5>There was a problem fetching your data, please try again.</h5>
        </>
      )}
    </Col>
  </Container>
);

export default ResponseWrapper;
