import React, { createContext, useReducer } from "react";
import Toast from "./Toast";
import { ToastMessageProps } from "./ToastProps";

interface ToastState {
  toasts: ToastMessageProps[];
}

const toastState: ToastState = {
  toasts: [],
};

interface StateProps extends ToastState {}

type AddToast = {
  type: "addToast";
  payload: Omit<ToastMessageProps, "identifier">;
};

type RemoveToast = {
  type: "removeToast";
  payload: ToastMessageProps;
};

interface StateContext {
  state: StateProps;
  dispatch: ({ type }: AddToast | RemoveToast) => void;
}

export const ToastContext = createContext<StateContext>({
  state: { ...toastState },
  dispatch: () => null,
});

function toastReducer(state: ToastState, { type, payload }: AddToast | RemoveToast): ToastState {
  switch (type) {
    case "addToast":
      return {
        ...state,
        toasts: [
          ...state.toasts,
          {
            identifier: new Date().toISOString(), // Set the identifier at creation time so that no two are alike
            ...payload,
          },
        ],
      };
    case "removeToast":
      if ("identifier" in payload) {
        return {
          ...state,
          toasts: state.toasts.filter((t) => t.identifier !== payload.identifier),
        };
      }
      throw new Error("identifier not supplied when removing toast message");
    default:
      return state;
  }
}

function ToastProvider(props: { children: any }) {
  const [state, dispatch]: [ToastState, any] = useReducer(toastReducer, toastState);

  return (
    <ToastContext.Provider value={{ state, dispatch }}>
      {props.children}
      {state.toasts.map((toast: ToastMessageProps) => (
        <Toast key={toast.identifier} {...toast} />
      ))}
    </ToastContext.Provider>
  );
}

export default ToastProvider;
