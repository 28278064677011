import React, { useEffect, useRef, useContext } from "react";
import { Toast, Alert } from "react-bootstrap";
import { createPortal } from "react-dom";
import { ToastContext } from "./ToastContext";
import { ToastMessageType, ToastBadgeProps, ToastMessageProps } from "./ToastProps";

const getToastAlert = (type: ToastMessageType): ToastBadgeProps => {
  return type === "success"
    ? { toastBadge: "mdi-check", heading: "Success", variant: "success" }
    : { toastBadge: "mdi-close", heading: "Action Failed", variant: "danger" };
};

const ToastMessage = (props: ToastMessageProps) => {
  const { dispatch } = useContext(ToastContext);

  const elRef = useRef<Element | null>(null);

  const { toastBadge, heading, variant }: ToastBadgeProps = getToastAlert(props.type);
  const modalRoot = document.getElementById("toast-root") as HTMLElement;

  if (!modalRoot || !elRef.current) {
    const newEl = document.createElement("div");
    newEl.id = "TOAST_" + props.identifier;
    elRef.current = newEl;
  }

  useEffect(() => {
    if (elRef.current) modalRoot.append(elRef.current);
    console.log(props.identifier, props.message);
  }, [modalRoot, props.identifier, props.message]);

  const dispatchTimeout = props.type === "success" ? 3000 : 7000; // Error Messages stay for longer so they can be read

  setTimeout(() => {
    const removeEl = document.getElementById("TOAST_" + props.identifier);
    if (removeEl) {
      removeEl.remove();
      dispatch({ type: "removeToast", payload: props });
    }
  }, dispatchTimeout);

  return createPortal(
    <Toast id={props.identifier} show={true} style={{ width: 300 }}>
      <Alert variant={variant} style={{ marginBottom: 0 }}>
        <Alert.Heading style={{ display: "flex", justifyContent: "space-between" }}>
          <h3>{heading}</h3>
          <span className={`mdi ${toastBadge}`} />
        </Alert.Heading>
        <p>{props.message}</p>
      </Alert>
    </Toast>,
    elRef.current
  );
};

export default React.memo(ToastMessage);
